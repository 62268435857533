import React from 'react';
// import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './loading';

const PrivateRoute = ({ component, ...args }) => {
    const Cp = withAuthenticationRequired( component, { onRedirecting: () => <div className="wrapper"><Loading /></div> });
    return <Cp {...args} />
}

export default PrivateRoute;