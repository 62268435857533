import './App.css';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/auth/private-route';
import Login from './pages/login';
import ThemeContext from './context/theme-context';
import Content from './components/docs';
import Layout from './components/layout/index';
function App() {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
            <Routes>
              <Route path="/" exact element={<PrivateRoute component={Content} theme={theme} slug="overview" />} />
              <Route path="/about" element={<PrivateRoute component={Content} theme={theme} slug="about" />} />
              <Route path="/where" element={<PrivateRoute component={Content} theme={theme} slug="where" />} />
              <Route path="/first-steps" element={<PrivateRoute component={Content} theme={theme} slug="first-steps" />} />
              <Route path="/benefits-perks" element={<PrivateRoute component={Content} theme={theme} slug="benefits" />} />
              <Route path="/engineering-process" element={<PrivateRoute component={Content} theme={theme} slug="engineering-process" />} />
              <Route path="/engineering-rituals" element={<PrivateRoute component={Content} theme={theme} slug="engineering-rituals" />} />
              <Route path="/career-development" element={<PrivateRoute component={Content} theme={theme} slug="career-development" />} />
              <Route path="/career-progression-framework" element={<PrivateRoute component={Content} theme={theme} slug="career-progression-framework" />} />
              <Route path="/hiring-referral" element={<PrivateRoute component={Content} theme={theme} slug="hiring-referral" />} />
              
              <Route path="/login" exact element={<Login />} />
            </Routes>
        </Layout>
      )}
    </ThemeContext.Consumer>
  );
}

export default App;
