import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/sidebar.module.css';
import ESIcon from '../../assets/icon-es.svg';

const Sidebar = () => {
    const nav = [
        {
            title: 'Overview',
            path: '/'
        },
        {
            title: 'ES & You',
            path: '/about'
        },
        {
            title: 'Where & How we work',
            path: '/where'
        },
        {
            title: 'Benefits & Perks',
            path: '/benefits-perks'
        },
        {
            title: 'First Steps',
            path: '/first-steps'
        },
        {
            title: 'Engineering Process',
            path: '/engineering-process'
        },
        {
            title: 'Engineering Rituals',
            path: '/engineering-rituals'
        },
        {
            title: 'Career Development',
            path: '/career-development'
        },
        {
            title: 'Career Progression Framework',
            path: '/career-progression-framework'
        },
        {
            title: 'Hiring Referral',
            path: '/hiring-referral'
        },
    ]
    return(
        <>
        <Link to="/" className="site-header__logo">
            <img src={ESIcon} className="App-logo" alt="logo" />
            <h1>ES Handbook</h1>
        </Link>
        <ul className={styles.list}>
            {nav.map(function(link, i){
                return(
                    <li key={i}><Link to={link.path}>{link.title}</Link></li>
                )
            })}
        </ul>
        </>
    );
}

export default Sidebar;