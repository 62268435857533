import React, {useState, useEffect} from 'react';
import Sidebar from './sidebar';
import Header from '../../components/layout/header';
import raw from 'raw.macro';
import ReactMarkdown from 'react-markdown';
import styles from './css/content.module.css';

const MarkdownContent = (props) => {
    const [content, setContent] = useState(null);
    const { theme } = props;

    useEffect(() => {
        const slug = props.slug || 'home';
        async function fetchData() {
            // console.log('abc', slug);
            const content = raw(`../../content/${slug}.md`);
            setContent(content);
        }
        fetchData();
    }, [props.slug]);

    return(
        <>
            <div className="container">
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className={styles.main}>
                    <Header 
                        onThemeChange={theme.toggleDark}
                        isDark={theme.dark ? true : false}
                    />
                    <ReactMarkdown children={content} />
                </div>
            </div>
        </>
    );
}

export default MarkdownContent;