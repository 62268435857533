import React from 'react';
import Loader from '../../assets/loader.svg';
// const loading = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const Loading = () => (
    <div className="spinner">
        <img src={Loader} alt="Loading" />
    </div>
);

export default Loading;
