import React from 'react';
import AuthenticationButton from '../auth/authenticate-button';
import { useAuth0 } from '@auth0/auth0-react';

const Header = (props) => {
    const { isDark, onThemeChange } = props;
    const { isLoading, user, isAuthenticated } = useAuth0();
    return(
        <header className="site-header">
            {!isLoading ?
            <>
                {/* <nav>
                    <Link to="/first">First</Link>
                </nav> */}
                <div className="site-header__right">
                    {isAuthenticated ? <span>Hello, {user?.given_name}</span> : null}
                    <AuthenticationButton />
                    {/* <div className="dark-mode-switcher">
                        <input checked={isDark} aria-label="Switch to Dark Mode" onChange={onThemeChange} type="checkbox" id="switcher" />
                        <label htmlFor="switcher" aria-label="Switch for Dark Mode"></label>
                    </div> */}
                </div>
            </>
            : null}
        </header>
    );
}

export default Header;