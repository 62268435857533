import React from 'react';
const defaultState = {
    dark: false,
    toggleDark: () => {},
}
const ThemeContext = React.createContext(defaultState)
const supportsDarkMode = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches === true
class ThemeProvider extends React.Component {
    state = {
        dark: false,
    }
    toggleDark = () => {
        let dark = !this.state.dark
        localStorage.setItem("dark", JSON.stringify(dark))
        this.setState({ dark })
        document.body.classList.toggle('dark-mode');
    }
    componentDidMount() {
        // Getting dark mode value from localStorage!
        const lsDark = JSON.parse(localStorage.getItem("dark"))
        if (lsDark) {
            this.setState({ dark: lsDark })
            if(lsDark){
                document.body.classList.add('dark-mode');
            }
        } else if (supportsDarkMode()) {
            this.setState({ dark: true })
            document.body.classList.add('dark-mode');
        }
    }
    render() {
        const { children } = this.props
        const { dark } = this.state
        return (
            <ThemeContext.Provider
            value={{
                dark,
                toggleDark: this.toggleDark,
            }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}
export default ThemeContext
export { ThemeProvider }
