import React from 'react';

const Layout = (props) => {
    const {children, theme} = props;
    return(
        <div className="app-root">
            {React.Children.map(children, (child) =>
                React.cloneElement(child, { theme })
        )}
        </div>
    );
}

export default Layout;