import React from 'react';
import AuthenticationButton from '../components/auth/authenticate-button';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/auth/loading';

const Login = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    if(isLoading){
        return(
            <div className="wrapper">
                <Loading />
            </div>
        )
    }
    return(
        <>
        {!isAuthenticated && !isLoading ?
        <div className="wrapper">
            <div className="login-action">
                <h1>Login</h1>
                <p>Please login with your ES ID to view the handbook.</p>
                <AuthenticationButton />
            </div>
        </div> : null}
        </>
    );
}

export default Login;